import { Content } from "./Content"
import { TextBlock, TextStyle } from "./TextBlock"
import { LatexBlock, LatexStyle } from "./LatexBlock"
import { CursorPosition } from "./CursorPosition"
import { CursorData } from "../Common";

export class Cursor {

	private cursorPosition: CursorPosition;
	private selectionEnd: CursorPosition;
	private cursorHTMLElement: HTMLElement;
	private DOUBLE_CLICK_BOUNDS: string[] = [" ", "\t", "\n"];
	private readonly content: Content;

	constructor(content: Content) {
		this.content = content;
		this.cursorPosition = new CursorPosition(0, 0, 0);
		this.selectionEnd = new CursorPosition(0, 0, 0);
		this.cursorHTMLElement = this.createCursor();
	}

	/* modification keys */
	public backspace() {
		if (!this.inASelection()) {
			this.cursorPosition.naiveDecrement(this.content);
		} else {
			this.cursorPosition.copy(CursorPosition.min(this.cursorPosition, this.selectionEnd));
		}
		this.selectionEnd.copy(this.cursorPosition);
	}

	public delete() {
		let currentBlock = this.content.at(this.cursorPosition.getParagraphNumber()).at(this.cursorPosition.getBlockNumber());
		if (!this.inASelection()) {
			if (this.cursorPosition.getOffset() == currentBlock.length() && !this.cursorPosition.atEndOfParagraph(this.content)) {
				this.cursorPosition.naiveIncrement(this.content);
			}
		} else {
			this.cursorPosition.copy(CursorPosition.min(this.cursorPosition, this.selectionEnd));
		}
		this.selectionEnd.copy(this.cursorPosition);
	}

	public insertCharacter() {
		this.cursorPosition.copy(CursorPosition.min(this.cursorPosition, this.selectionEnd));
		this.cursorPosition.increment(this.content);
		this.selectionEnd.copy(this.cursorPosition);
	}

	public insertText(text: string) {
		this.cursorPosition.copy(CursorPosition.min(this.cursorPosition, this.selectionEnd));
		this.cursorPosition.incrementText(this.content, text);
		this.selectionEnd.copy(this.cursorPosition);
	}

	/* Navigation Keys */
	public arrowLeft(shiftKey: boolean) {
		if (shiftKey) {
			this.selectionEnd.decrement(this.content);
			this.expandDynamicLatexSelection(true);
		} else {
			if (!this.inASelection()) { // we are not in a selection
				this.cursorPosition.decrement(this.content);
				this.selectionEnd.copy(this.cursorPosition);
			} else if (this.cursorPosition.greaterThan(this.selectionEnd)) { // if we are in a selection, cursor should snap to "beginning" of selection
				this.cursorPosition.copy(this.selectionEnd);
			} else { // if we are in a selection, cursor should snap to "end" of selection
				this.selectionEnd.copy(this.cursorPosition);
			}
		}
	}

	public arrowRight(shiftKey: boolean) {
		if (shiftKey) {
			this.selectionEnd.increment(this.content);
			this.expandDynamicLatexSelection(false);
		} else {
			if (!this.inASelection()) {
			  this.cursorPosition.increment(this.content);
			  this.selectionEnd.copy(this.cursorPosition);
			} else if (this.cursorPosition.lessThan(this.selectionEnd)) { // if we are in a selection, cursor should snap to "end" of selection
			  this.cursorPosition.copy(this.selectionEnd);
			} else { // if we are in a selection, cursor should snap to "beginning" of selection
			  this.selectionEnd.copy(this.cursorPosition);
			}
		}
	}

	public arrowUp(shiftKey: boolean, newSelectionEndPosition: CursorPosition) {
		if (shiftKey) {
			this.selectionEnd.copy(newSelectionEndPosition);
		} else {
			if (!this.inASelection()) {
				this.selectionEnd.copy(newSelectionEndPosition);
				this.cursorPosition.copy(this.selectionEnd);
			} else if (this.cursorPosition.lessThan(this.selectionEnd)) { // if we are in a selection, cursor should snap to "beginning" of selection
				this.selectionEnd.copy(this.cursorPosition);
			} else { // if we are in a selection, cursor should snap to "end" of selection
				this.cursorPosition.copy(this.selectionEnd);
			}
		}
	}

	public arrowDown(shiftKey: boolean, newSelectionEndPosition: CursorPosition) {
		if (shiftKey) {
			this.selectionEnd.copy(newSelectionEndPosition);
		} else {
			if (!this.inASelection()) {
				this.selectionEnd.copy(newSelectionEndPosition);
				this.cursorPosition.copy(this.selectionEnd);
			} else if (this.cursorPosition.lessThan(this.selectionEnd)) {  // if we are in a selection, cursor should snap to "end" of selection
				this.cursorPosition.copy(this.selectionEnd);
			} else {  // if we are in a selection, cursor should snap to "beginning" of selection
				this.selectionEnd.copy(this.cursorPosition);
			}
		}
	}

	/* Mouse Stuff */
	public mouseDown(newCursorPositon: CursorPosition) {
		this.cursorPosition.copy(newCursorPositon);
		this.selectionEnd.copy(this.cursorPosition);
	}

	public mouseMove(newSelectionEndPositon: CursorPosition) {
		// prevents user from selecting beyond Mathquill
		if (this.cursorPosition.inDynamicLatex(this.content)) {
			return;
		}

		// if the new selection end position is still in dynamic latex, then we assume that the expandDynamicLatexSelection
		// method was called when selection end was not in dynamic latex and newSelectionEnd was. So, since the
		// selection was already "expanded" to include the entire dynamic latex block, we do nothing by returning.
		if (this.selectionEnd.inDynamicLatex(this.content) && newSelectionEndPositon.inDynamicLatex(this.content)) {
			return;
		}

		let directionLeft = newSelectionEndPositon.lessThan(this.selectionEnd);
		this.selectionEnd.copy(newSelectionEndPositon);
		this.expandDynamicLatexSelection(directionLeft);
	}

	public doubleClick() {
		// Find the previous and next space, tab, or newline from cursor position
		while (!this.DOUBLE_CLICK_BOUNDS.includes(this.content.charAt(this.cursorPosition)) && 
				!this.cursorPosition.atBeginningOfParagraph()) {
			this.cursorPosition.decrement(this.content);
		}

		while (!this.DOUBLE_CLICK_BOUNDS.includes(this.content.charAt(this.selectionEnd)) && 
				!this.selectionEnd.atEndOfParagraph(this.content)) {
			this.selectionEnd.increment(this.content);
		}

		if (!this.cursorPosition.atBeginningOfParagraph()) {
			this.cursorPosition.increment(this.content);
		}
	}

	public tripleClick() {
		while (this.content.charAt(this.cursorPosition) != "\n" && !this.cursorPosition.atBeginningOfParagraph()) {
			this.cursorPosition.decrement(this.content);
		}

		while (this.content.charAt(this.selectionEnd) != "\n" && !this.selectionEnd.atEndOfParagraph(this.content)) {
			this.selectionEnd.increment(this.content);
		}

		if (!this.cursorPosition.atBeginningOfParagraph()) {
			this.cursorPosition.increment(this.content);
		}
	}

	/* Cut, Copy (does not need a method), Paste, and SelectAll */
	public cut() {
		this.cursorPosition.copy(CursorPosition.min(this.cursorPosition, this.selectionEnd));
		this.selectionEnd.copy(this.cursorPosition);
	}
	
	public paste(paste: string) {
		// if we're in a selection, then we are basically cutting, and then adding characters
		this.cut();
		this.cursorPosition.incrementText(this.content, paste);
		this.selectionEnd.copy(this.cursorPosition);
	}

	public selectAll() {
		let lastParagraphNumber = this.content.getParagraphs().length - 1;
		let lastBlockNumber = this.content.at(lastParagraphNumber).getBlocks().length - 1;
		let lastOffset = this.content.at(lastParagraphNumber).at(lastBlockNumber).length();

		this.cursorPosition = new CursorPosition(0, 0, 0);
		this.selectionEnd = new CursorPosition(lastParagraphNumber, lastBlockNumber, lastOffset);
	}

	public style(cursorPosition: CursorPosition, selectionEnd: CursorPosition) {
		this.cursorPosition.copy(cursorPosition);
		this.selectionEnd.copy(selectionEnd);
	}

	public insertParagraph(cursorPosition: CursorPosition, selectionEnd: CursorPosition) {
		this.cursorPosition.copy(cursorPosition);
		this.selectionEnd.copy(selectionEnd);
	}

	/* Rendering and Removing */
	public render(editor: HTMLElement, blockMap: Map<String, Array<Array<Number>>>) {
		if (this.inDynamicLatex()) {
			return;
		}

        let start = CursorPosition.min(this.cursorPosition, this.selectionEnd);
        let end = CursorPosition.max(this.cursorPosition, this.selectionEnd);

		if (!start.equals(end)) {
			let selectionRanges: Range[] = [];

			let startPage = start.getBlockCoordinates(blockMap)[0];
			let endPage = end.getBlockCoordinates(blockMap)[0];

			for (let page = startPage; page <= endPage; page++) {
				for (let paragraph = 0; paragraph < editor.children[page].children.length; paragraph++) {
					for (let block = 0; block < editor.children[page].children[paragraph].children.length; block++) {
						let b = editor.children[page].children[paragraph].children[block] as HTMLElement;
						let range = this.getElementSelectionRange(b, start, end);
						if (range != null) {
							selectionRanges.push(range);
						}
					}
				}
			}

			for (let i = 0; i < selectionRanges.length; i++) {
				let selectionSpanEnd: HTMLElement = document.createElement("span");
				selectionSpanEnd.classList.add("selection");
				selectionRanges[i].surroundContents(selectionSpanEnd);
			}

		} else {
			let currentBlock = this.content.at(this.cursorPosition.getParagraphNumber()).at(this.cursorPosition.getBlockNumber());
			if (currentBlock instanceof TextBlock && currentBlock.getStyle(TextStyle.ITALIC)) {
				this.cursorHTMLElement.classList.add("cursor-italic");
			} else {
				this.cursorHTMLElement.classList.remove("cursor-italic");
			}

			let blockCoorindates = this.getCursorPosition().getBlockCoordinates(blockMap);
			let currentParagraphElement = editor.children[blockCoorindates[0]].children[blockCoorindates[1]];
			let adjustedOffset = start.getOffset() - blockCoorindates[3];

			if (currentParagraphElement.children[blockCoorindates[2]].firstChild == null) { // handling a 0 length block
				currentParagraphElement.insertBefore(this.cursorHTMLElement, currentParagraphElement.children[blockCoorindates[2]])
			} else {
				let cursorRange: Range = document.createRange()
				cursorRange.setStart(currentParagraphElement.children[blockCoorindates[2]].firstChild!, adjustedOffset);
				cursorRange.setEnd(currentParagraphElement.children[blockCoorindates[2]].firstChild!, adjustedOffset);
				cursorRange.insertNode(this.cursorHTMLElement);
			}
    	}
  	}

	public getElementSelectionRange(block: HTMLElement, start: CursorPosition, end: CursorPosition): Range | null {
		let range = new Range();
		let structure = block.getAttribute("content-structure")!;
		let elementParagraphNumber = parseInt(structure.split("-")[0]);
		let elementBlockNumber = parseInt(structure.split("-")[1]);
		let startingOffset = parseInt(block.id.split("-")[3]);

		let minimumCursorPosition = new CursorPosition(elementParagraphNumber, elementBlockNumber, startingOffset);
		let maximumCursorPosition = new CursorPosition(elementParagraphNumber, elementBlockNumber, startingOffset + block.innerText.length);

		if (
			(start.lessThanOrEqualTo(minimumCursorPosition) && end.lessThanOrEqualTo(minimumCursorPosition)) || 
			(start.greaterThanOrEqualTo(maximumCursorPosition) && end.greaterThanOrEqualTo(maximumCursorPosition))
		) {
			return null; // this block is not in any way part of the selection
		}

		if (
			(start.lessThanOrEqualTo(maximumCursorPosition) && start.inDynamicLatex(this.content)) || 
			(end.greaterThanOrEqualTo(minimumCursorPosition)) && end.inDynamicLatex(this.content)
		) {
			range.selectNodeContents(block); // the selection is in dynamic latex
			return range;
		}

		if (start.lessThan(minimumCursorPosition)) {
			range.setStart(block.firstChild!, 0);
		} else if (start.lessThan(maximumCursorPosition)) {
			range.setStart(block.firstChild!, start.getOffset() - startingOffset);
		}

		if (end.greaterThan(maximumCursorPosition)) {
			range.setEnd(block.firstChild!, block.innerText.length);
		} else if (end.greaterThan(minimumCursorPosition)) {
			range.setEnd(block.firstChild!, end.getOffset() - startingOffset);
		}

		return range;
	}

	public removeCursor() {
        this.cursorHTMLElement.remove();
    }

	/* Utils */

	// This method updates the cursor appropiately when content is purified.
	// It takes in the index of the paragraph that will be deleted as part of purification.
	// The index must valid.
	public purifyContent(index: number) {
		// If the following is not true: the cursor is inside a 0 length paragraph and is not in a selection
		if (!(this.cursorPosition.getParagraphNumber() == index && this.selectionEnd.getParagraphNumber() == index)) {
			this.cursorPosition.purifyContent(this.content, index);
			this.selectionEnd.purifyContent(this.content, index);
		}
	}

	// This method updates the cursor appropiately when a paragraph is purified.
	// It takes in the index of the paragraph being purified and the index
	// of the block that will be deleted as part of purification.
	// The index must valid.
	public purifyParagraph(paragraphIndex: number, blockIndex: number) {
		// If the following is not true: the cursor is inside a 0 length block and is not in a selection
		if (!(
				this.cursorPosition.getParagraphNumber() == paragraphIndex &&
				this.selectionEnd.getParagraphNumber() == paragraphIndex &&
				this.cursorPosition.getBlockNumber() == blockIndex && 
				this.selectionEnd.getBlockNumber() == blockIndex)
			) {
				this.cursorPosition.purifyParagraph(this.content.at(paragraphIndex), paragraphIndex, blockIndex);
				this.selectionEnd.purifyParagraph(this.content.at(paragraphIndex), paragraphIndex, blockIndex);
		}
	}

	// This method updates the cursor appropiately when content is coalesced.
	// It takes in the index of the parent paragraph (which will be expanded)
	// and the index of the child paragraph (which will be deleted) as part of coalescing.
	// The parentIndex must be equal to the childIndex - 1, and must be valid.
	public coalesceContent(parentIndex: number, childIndex: number) {
		this.cursorPosition.coalesceContent(this.content, parentIndex, childIndex);
		this.selectionEnd.coalesceContent(this.content, parentIndex, childIndex);
	}

	// This method updates the cursor appropiately when a paragraph is coalesced.
	// It takes in the index of the parent block (which will be expanded)
	// and the index of the child block (which will be deleted) as part of coalescing.
	// The parentIndex must be equal to the childIndex - 1, and must be valid.
	public coalesceParagraph(paragraphIndex: number, parentIndex: number, childIndex: number) {
		this.cursorPosition.coalesceParagraph(this.content.at(paragraphIndex), paragraphIndex, parentIndex, childIndex);
		this.selectionEnd.coalesceParagraph(this.content.at(paragraphIndex), paragraphIndex, parentIndex, childIndex);
	}

	public insideParagraph(paragraphNumber: number) {
		let paragraph = this.content.at(paragraphNumber);
		let lastOffset = paragraph.at(paragraph.length() - 1).length();

		let start = new CursorPosition(paragraphNumber, 0, 0);
		let end = new CursorPosition(paragraphNumber, paragraph.length() - 1, lastOffset);

		let beforeParagraph = this.cursorPosition.lessThan(start) && this.selectionEnd.lessThan(start);
		let afterParagraph = this.cursorPosition.greaterThan(end) && this.selectionEnd.greaterThan(end);

		// notPartOfSelection is true if this paragraph is NOT part of the selection in any capacity
		let notPartOfSelection = beforeParagraph || afterParagraph;
		return !notPartOfSelection;
	}

	public insideBlock(paragraphNumber: number, blockNumber: number) {
		let paragraph = this.content.at(paragraphNumber);
		let lastOffset = paragraph.at(blockNumber).length();

		let start = new CursorPosition(paragraphNumber, blockNumber, 0);
		let end = new CursorPosition(paragraphNumber, blockNumber, lastOffset);

		let beforeBlock = this.cursorPosition.lessThan(start) && this.selectionEnd.lessThan(start);
		let afterBlock = this.cursorPosition.greaterThan(end) && this.selectionEnd.greaterThan(end);

		// notPartOfSelection is true if this block is NOT part of the selection in any capacity
		let notPartOfSelection = beforeBlock || afterBlock;
		return !notPartOfSelection;
	}

	public inDynamicLatex(): boolean {
		return this.cursorPosition.inDynamicLatex(this.content) && this.selectionEnd.inDynamicLatex(this.content);
	}

	public activeLatexBlockPosition(): [number, number] {
		if (this.inDynamicLatex()) {
			return [this.cursorPosition.getParagraphNumber(), this.cursorPosition.getBlockNumber()];
		}
		return [-1, -1];
	}

	public vanillaLatexMoveOut(newCursorPositon: CursorPosition, newSelectionEnd: CursorPosition) {
		this.cursorPosition.copy(newCursorPositon);
		this.selectionEnd.copy(newSelectionEnd);
	}

	public dynamicLatexMoveOut(newCursorPositon: CursorPosition, newSelectionEnd: CursorPosition, direction: number) {
		this.cursorPosition.copy(newCursorPositon);
		this.selectionEnd.copy(newSelectionEnd);

		if (direction == -1) { // moving out left
			let previousBlockNumber = this.cursorPosition.getBlockNumber() - 1;
			let previousBlockLength = this.content.at(this.cursorPosition.getParagraphNumber()).at(previousBlockNumber).length();
			this.cursorPosition.copy(new CursorPosition(this.cursorPosition.getParagraphNumber(), previousBlockNumber, previousBlockLength));
		} else { // moving out right
			let nextBlockNumber = this.cursorPosition.getBlockNumber() + 1;
			this.cursorPosition.copy(new CursorPosition(this.cursorPosition.getParagraphNumber(), nextBlockNumber, 0));
		}
		this.selectionEnd.copy(this.cursorPosition);
	}

	public expandDynamicLatexSelection(isDirectionLeft: boolean) {
		let selectionEndParagraphNumber = this.selectionEnd.getParagraphNumber();
		let selectionEndBlockNumber = this.selectionEnd.getBlockNumber();
		let selectionEndBlock = this.content.at(selectionEndParagraphNumber).at(selectionEndBlockNumber);
		if (selectionEndBlock instanceof LatexBlock && selectionEndBlock.getStyle(LatexStyle.DYNAMIC)) {
			if (isDirectionLeft) {
				this.selectionEnd.copy(new CursorPosition(selectionEndParagraphNumber, selectionEndBlockNumber, 0));
			} else {
				this.selectionEnd.copy(new CursorPosition(selectionEndParagraphNumber, selectionEndBlockNumber, selectionEndBlock.length()));
			}
		}
	}

	public getSelectionText(): string {
		let start = CursorPosition.min(this.cursorPosition, this.selectionEnd);
		let end = CursorPosition.max(this.cursorPosition, this.selectionEnd);
		return this.content.substring(start, end);
	}

	public getTextBeforeCursor(): string {
		return this.content.substring(new CursorPosition(0, 0, 0), this.cursorPosition);
	}

	public getTextAfterCursor(): string {
		let finalParagraphIndex = this.content.getParagraphs().length - 1;
		let finalBlockIndex = this.content.at(finalParagraphIndex).getBlocks().length - 1;
		let finalBlock = this.content.at(finalParagraphIndex).at(finalBlockIndex);
		return this.content.substring(this.cursorPosition, new CursorPosition(finalParagraphIndex, finalBlockIndex, finalBlock.length()));
	}

	private createCursor(): HTMLElement {
		let cusor: HTMLElement = document.createElement("div");
		cusor.id = "cursor";
		return cusor;
	}

	/* Getters */
	public getCursorPosition() {
		return this.cursorPosition;
	}

	public getSelectionEnd() {
		return this.selectionEnd;
	}

	public inASelection() {
		return !this.cursorPosition.equals(this.selectionEnd);
	}

	/* Serialization methods for state */

	public serialize(): CursorData {
        let cursorData: CursorData = {
			cursorPosition: CursorPosition.duplicate(this.cursorPosition),
			selectionEnd: CursorPosition.duplicate(this.selectionEnd),
		}
        return cursorData;
    }

    public deSerialize(data: CursorData) {
        this.cursorPosition.copy(data.cursorPosition);
		this.selectionEnd.copy(data.selectionEnd);
    }
}
