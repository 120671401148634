import {
    Box,
    Table,
    TableContainer,
    Td,
    Thead,
    Tr,
    Th,
    Tbody,
    Heading,
    Button,
    Tooltip,
    Modal,
    ModalOverlay,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalFooter,
    FormControl,
    FormLabel,
    Input,
    useDisclosure,
    Link as ChakraLink,
} from "@chakra-ui/react";
import { Navbar } from "../components/Navbar";
import { Footer } from "../components/Footer";
import { useState, useRef, useEffect } from "react";
import { useNavigate, Link as ReactRouterLink } from "react-router-dom";
import dayjs from "dayjs";
import { AwsLibrary, DocumentMetadata } from "../Common";
import { Encrypter } from "../Encrypter";
import { useAuth } from "../auth/Authentication";

interface DocumentList {
    // needed to pass to ListView
    documentList: DocumentMetadata[];
}

const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export const DocumentsPage = () => {
    const [documentList, setDocumentList] = useState([] as DocumentMetadata[]);
    const navigate = useNavigate();

    let { useGetUserData } = useAuth();
    let userData = useGetUserData();

    const fetchDocuments = async () => {
        const [documentList, error] = await AwsLibrary.getDocumentsForUser(
            userData.email
        );

        if (error != null || documentList == null) {
            // handle this by navigating to error page
            navigate("/error");
        } else {
            setDocumentList(documentList);
        }
    };

    useEffect(() => {
        // this function is called every time this page is loaded
        fetchDocuments();
    }, []);

    return (
        <Box minHeight="100vh" h="auto" w="100%">
            <Navbar />
            <NewDocumentWindow />
            <ListView documentList={documentList} />
            <Footer position="absolute" left="0" bottom="0" padding={3} />
        </Box>
    );
};

const NewDocumentWindow = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [fileName, setFileName] = useState("");
    const initialRef = useRef(null);
    const navigate = useNavigate();
    let { useGetUserData } = useAuth();
    let userData = useGetUserData();

    const handleSubmit = async (email: string, fileName: string) => {
        const err = await AwsLibrary.createDocument(email, fileName);
        if (err != null) {
            // handle this by navigating to error page
            navigate("/error");
        }
        onClose();
        const documentName = `${email}/${fileName}.json`;
        const ciphertext = Encrypter.encrypt(documentName);
        navigate(`/editor/${ciphertext}`);
    };

    return (
        <Box marginTop="2rem" paddingLeft="6rem">
            <Button colorScheme="blue" onClick={onOpen}>
                Create Document
            </Button>
            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Create document</ModalHeader>
                    <ModalBody paddingBottom="1rem">
                        <FormControl>
                            <FormLabel>Document Title</FormLabel>
                            <Input
                                onChange={(event: any) =>
                                    setFileName(event.target.value)
                                }
                                ref={initialRef}
                                placeholder="Your document title"
                            />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={onClose} marginRight="0.5rem">
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={() =>
                                handleSubmit(userData.email, fileName)
                            }
                        >
                            Create
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

const ListView = ({ documentList }: DocumentList) => {
    return (
        <TableContainer width="100%" padding="5rem" paddingTop="2rem">
            <Table>
                <Thead>
                    <Tr>
                        <Th>Document Name</Th>
                        <Th>Last Edited</Th>
                        <Th>Owner</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {documentList.map(
                        (documentMetadata: DocumentMetadata, index: number) => {
                            const { title, lastModified, owner } =
                                documentMetadata;
                            const dayjsLastModified = (dayjs() as any).to(
                                dayjs(lastModified)
                            );
                            const dateString = lastModified?.toLocaleString();
                            const extracted = title?.match(/\.edu\/(.*)\.json/);

                            return (
                                <Tr key={index}>
                                    <Td>
                                        {
                                            <ChakraLink
                                                as={ReactRouterLink}
                                                to={
                                                    title != undefined
                                                        ? `/editor/${Encrypter.encrypt(
                                                              title
                                                          )}`
                                                        : "/error"
                                                }
                                                isExternal
                                            >
                                                {extracted != null &&
                                                extracted.length > 1
                                                    ? extracted[1]
                                                    : title}
                                            </ChakraLink>
                                        }
                                    </Td>
                                    <Td>
                                        {
                                            <Tooltip label={dateString}>
                                                {dayjsLastModified}
                                            </Tooltip>
                                        }
                                    </Td>
                                    <Td>{owner}</Td>
                                </Tr>
                            );
                        }
                    )}
                </Tbody>
            </Table>
        </TableContainer>
    );
};
