import { State } from "./State";
import { Content } from "./Content";
import { Page } from "./Page";
import { Paragraph } from "./Paragraph";
import { LatexParagraph } from "./LatexParagraph";
import { TextStyle } from "./TextBlock";
import { LatexStyle } from "./LatexBlock";
import { Cursor } from "./Cursor";
import { CursorPosition } from "./CursorPosition";
import "katex/dist/katex.min.css";
import { MathQuillLoader } from "mathquill-typescript"
import { animateScroll } from 'react-scroll';

export class Controller {
    public static CLICK_DELAY: number = 500;
    public static SCROLL_DURATION: number = 100;
    public static SAVE_DURATION: number = 500;
    public static CHARACTERS = new Set(["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z","[","]","\\",";","'",",",".","/","{","}","|",":","\"","<",">","?","1","2","3","4","5","6","7","8","9","0","!","@","#","$","%","^","&","*","(",")","~","`","-","_","+","="]);
    public static ADDITIONAL_EDIT_KEYS = new Set(["Backspace", "Delete", "Enter", "Tab", " "]);
    public static NAVIGATION_KEYS = new Set(["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"]);
    public static ALL_KEYS = new Set([...this.CHARACTERS, ...this.ADDITIONAL_EDIT_KEYS, ...this.NAVIGATION_KEYS]);

    private MQ: any;
    private editor: HTMLElement;
    private state: State;
    private previousClickPosition: CursorPosition;
    private clicks: number;
    private scrolling: boolean;
    private saveTimerActive: boolean;
    private savedInCloud: boolean;
    private wasInLatex: [number, number];
    private blockMap: Map<String, Array<Array<Number>>>;
    private setRenderError: (error: Error | null) => void;

    constructor(setRenderError: (error: Error | null) => void, editor: HTMLElement, content = new Content()) {
        MathQuillLoader.loadMathQuill({'mode': 'prod'}, (mathquill: any) => {
            this.MQ = mathquill.getInterface(2);
        })
        this.setRenderError = setRenderError;

        this.editor = editor;
        this.state = new State(content, new Cursor(content));
        this.previousClickPosition = new CursorPosition(0,0,0);
        this.clicks = 0;
        this.scrolling = false;
        this.saveTimerActive = false;
        this.savedInCloud = true;
        this.wasInLatex = this.state.cursor().activeLatexBlockPosition();
        this.blockMap = new Map<String, Array<Array<Number>>>();

        this.renderContent();
        this.renderCursor();
        this.triggerPagesInViewPort(this.editor);
        this.state.save(this.getFirstModifiedPage());
    }

    public handleBold(e: any) {
        this.textStyleHandler(TextStyle.BOLD);
        e.target.blur();
    }

    public handleItalic(e: any) {
        this.textStyleHandler(TextStyle.ITALIC);
        e.target.blur();
    }

    public handleUnderline(e: any) {
        this.textStyleHandler(TextStyle.UNDERLINE);
        e.target.blur();
    }

    public handleStrikethrough(e: any) {
        this.textStyleHandler(TextStyle.STRIKETHROUGH);
        e.target.blur();
    };

    public handleClearFormatting(e: any) {
        this.textStyleHandler(null);
        e.target.blur();
    };

    public handleDynamicLatex(e: any) {
        this.latexStyleHandler(LatexStyle.DYNAMIC);
        e.target.blur();
    };

    public handleVanillaLatex(e: any) {
        this.latexStyleHandler(LatexStyle.VANILLA);
        e.target.blur();
    };

    public handleLatexParagraph(e: any) {
        this.insertParagraphHandler(new LatexParagraph());
        e.target.blur();
    };

    public async handleUndo() {
        let currentFirstDirtyPage = this.getFirstModifiedPage();
        this.state.undo();
        let oldFirstDirtyPage = this.state.firstModifiedPage();
        let firstModifiedPage = Math.min(currentFirstDirtyPage, oldFirstDirtyPage);
        this.savedInCloud = false;

        this.renderContent(true, firstModifiedPage);
        await this.scrollIfNeeded();
        this.renderCursor();
    }

    public async handleRedo() {
        let currentFirstDirtyPage = this.getFirstModifiedPage();
        this.state.redo();
        let oldFirstDirtyPage = this.state.firstModifiedPage();
        let firstModifiedPage = Math.min(currentFirstDirtyPage, oldFirstDirtyPage);

        this.savedInCloud = false;

        this.renderContent(true, firstModifiedPage);
        await this.scrollIfNeeded();
        this.renderCursor();
    }

    public handleSaveInCloud() {
        this.savedInCloud = true;
    }

    public handleCopy(e: ClipboardEvent) {
        e.preventDefault();
        e.clipboardData!.setData("text/plain", this.state.cursor().getSelectionText());
    }

    public async handleCut(e: ClipboardEvent) {
        e.preventDefault();
        let firstDirtyPage = this.getFirstModifiedPage();
        this.state.save(firstDirtyPage);

        e.clipboardData!.setData("text/plain", this.state.cursor().getSelectionText());
        this.state.content().cut(this.state.cursor());
        this.state.cursor().cut();
        this.savedInCloud = false;

        this.renderContent(true, firstDirtyPage);
        await this.scrollIfNeeded();
        this.renderCursor();
        this.state.save(this.getFirstModifiedPage());
    }

   public async handlePaste(e: ClipboardEvent) {
        e.preventDefault();
        let firstDirtyPage = this.getFirstModifiedPage();
        this.state.save(firstDirtyPage);

        let paste = (e.clipboardData || (window as any).clipboardData).getData("text");

        this.state.content().paste(paste, this.state.cursor());
        this.state.cursor().paste(paste);
        this.savedInCloud = false;

        this.renderContent(true, firstDirtyPage);
        await this.scrollIfNeeded();
        this.renderCursor();
        this.state.save(this.getFirstModifiedPage());
    }

    public async handleKeyDown(e: KeyboardEvent) {
        if (this.scrolling) {
            return;
        }

        if (!this.tupleEquals(this.wasInLatex, [-1, -1])) {
            this.wasInLatex = this.state.cursor().activeLatexBlockPosition();
            return;
        }

        this.wasInLatex = this.state.cursor().activeLatexBlockPosition();

        if (e.metaKey) {
            if (e.key == 'a') {
                e.preventDefault();
                this.state.cursor().selectAll();
                this.renderContent();
                this.renderCursor();
            } else if (e.key == 'b') {
                this.textStyleHandler(TextStyle.BOLD);
            } else if (e.key == 'i') {
                e.preventDefault();
                this.textStyleHandler(TextStyle.ITALIC);
            } else if (e.key == 'u') {
                this.textStyleHandler(TextStyle.UNDERLINE);
            } else if (e.key == 'x' && e.shiftKey) {
                this.textStyleHandler(TextStyle.STRIKETHROUGH);
            } else if (e.key == 'z' && e.shiftKey) {
                this.handleRedo();
            } else if (e.key == 'z') {
                this.handleUndo();
            } else if (e.key == '\\') {
                e.preventDefault();
                this.textStyleHandler(null);
            }
        }

        if (!e.metaKey && (Controller.CHARACTERS.has(e.key) || Controller.ADDITIONAL_EDIT_KEYS.has(e.key))) {
            this.savedInCloud = false;
            let firstDirtyPage = this.getFirstModifiedPage();
            let inSelection = this.state.cursor().inASelection();
            if (inSelection) {
                this.state.save(firstDirtyPage);
            } else if (!this.saveTimerActive) {
                this.saveTimerActive = true;
                setTimeout(() => {
                    this.state.save(firstDirtyPage);
                    this.saveTimerActive = false;
                }, Controller.SAVE_DURATION);
            }

            if (e.key == "Backspace") {
                this.state.content().backspace(this.state.cursor());
                this.state.cursor().backspace();
            } else if (e.key == "Delete") {
                this.state.content().delete(this.state.cursor());
                this.state.cursor().delete();
            } else if (e.key == "Enter") {
                let [newCursorPosition, newSelectionEnd, contentChanged] = this.state.content().vanillaLatexMoveOut(this.state.cursor(), 1);
                this.state.cursor().vanillaLatexMoveOut(newCursorPosition, newSelectionEnd);
                if (!contentChanged) {
                    this.state.content().addText('\n', this.state.cursor());
                }
                this.state.cursor().insertCharacter();
            } else if (e.key == "Tab") {
                e.preventDefault();
                this.state.content().addText('\t', this.state.cursor());
                this.state.cursor().insertCharacter();
            } else if (e.key == " ") {
                e.preventDefault();
                this.state.content().addText(' ', this.state.cursor());
                this.state.cursor().insertCharacter();
            } else if (Controller.CHARACTERS.has(e.key)) {
                this.state.content().addText(e.key, this.state.cursor());
                this.state.cursor().insertCharacter();
            }
            this.renderContent(true, firstDirtyPage);
            await this.scrollIfNeeded();
            this.renderCursor();

            if (inSelection) {
                this.state.save(this.getFirstModifiedPage());
            }
        } else if (Controller.NAVIGATION_KEYS.has(e.key)) {
            let firstDirtyPage = this.getFirstModifiedPage();

            if (e.key == 'ArrowLeft') {
                let [newCursorPosition, newSelectionEnd, _contentChanged] = this.state.content().vanillaLatexMoveOut(this.state.cursor(), -1);
                this.state.cursor().vanillaLatexMoveOut(newCursorPosition, newSelectionEnd);

                this.state.cursor().arrowLeft(e.shiftKey);
                this.renderContent(true, firstDirtyPage); // cursor position can change content
                await this.scrollIfNeeded();
            } else if (e.key == 'ArrowRight') {
                let [newCursorPosition, newSelectionEnd, _contentChanged] = this.state.content().vanillaLatexMoveOut(this.state.cursor(), 1);
                this.state.cursor().vanillaLatexMoveOut(newCursorPosition, newSelectionEnd);

                this.state.cursor().arrowRight(e.shiftKey);
                this.renderContent(true, firstDirtyPage); // cursor position can change content
                await this.scrollIfNeeded();
            } else if (e.key == 'ArrowUp') {
                e.preventDefault();
                let scrollFirst = (!this.state.cursor().inASelection() || e.shiftKey);
                let newSelectionEndPosition = new CursorPosition(-1, -1, -1);

                if (scrollFirst) {
                    if (this.scrolling) {
                        return;
                    }
                    this.removeCursor();
                    await this.scrollIfNeeded();
                    // Calculate one up shift of selectionEnd
                    let newSelectionRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
                    let newSelectionEndRange = Page.getXYCaretRange(newSelectionRect.left, newSelectionRect.bottom - (newSelectionRect.bottom - newSelectionRect.top + 3));
                    newSelectionEndPosition = this.cursorPositionFromRange(newSelectionEndRange);
                }

                this.state.cursor().arrowUp(e.shiftKey, newSelectionEndPosition);
                this.renderContent(true, firstDirtyPage); // cursor position can change content
                await this.scrollIfNeeded();
            } else if (e.key == 'ArrowDown') {
                e.preventDefault();
                let scrollFirst = (!this.state.cursor().inASelection() || e.shiftKey);
                let newSelectionEndPosition = new CursorPosition(-1, -1, -1);

                if (scrollFirst) {
                    if (this.scrolling) {
                        return;
                    }
                    this.removeCursor();
                    await this.scrollIfNeeded();
                    // Calculate one down shift of selectionEnd
                    let newSelectionRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
                    let newSelectionEndRange = Page.getXYCaretRange(newSelectionRect.left, newSelectionRect.bottom + (newSelectionRect.bottom - newSelectionRect.top + 1));
                    newSelectionEndPosition = this.cursorPositionFromRange(newSelectionEndRange);
                }

                this.state.cursor().arrowDown(e.shiftKey, newSelectionEndPosition);
                this.renderContent(true, firstDirtyPage); // cursor position can change content
                await this.scrollIfNeeded();
            }
            this.renderCursor();
        }
    }

    public handleMouseDown(evt: MouseEvent) {
        evt.preventDefault();
        this.wasInLatex = this.state.cursor().activeLatexBlockPosition();

        if (evt.button == 0) {
            this.removeCursor();
            this.clicks++;

            setTimeout(() => {
                this.clicks = 0;
            }, Controller.CLICK_DELAY);

            let clickRange = Page.getXYCaretRange(evt.clientX, evt.clientY);
            let newClickPosition = this.cursorPositionFromRange(clickRange);

            if (this.clicks == 3 && this.previousClickPosition.equals(newClickPosition)) {
                this.tripleClickAction();
                this.clicks = 0;
            } else if (this.clicks == 2 && this.previousClickPosition.equals(newClickPosition)) {
                this.doubleClickAction();
            } else {
                this.singleClickAction(evt);
            }

            if (newClickPosition != null) {
                this.previousClickPosition = newClickPosition;
            }
        }
    }

    public singleClickAction(evt: MouseEvent) {
        let clickRange = Page.getXYCaretRange(evt.clientX, evt.clientY);
        let newCursorPosition = this.cursorPositionFromRange(clickRange);

        if (newCursorPosition != null) {
            this.state.cursor().mouseDown(newCursorPosition);
        } else if (this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])) {
            let finalParagraphNumber = this.state.content().length() - 1;
            let finalBlockNumber = this.state.content().at(finalParagraphNumber).length() - 1;
            let finalBlockLength = this.state.content().at(finalParagraphNumber).at(finalBlockNumber).length();
            newCursorPosition = new CursorPosition(finalParagraphNumber, finalBlockNumber, finalBlockLength);
            this.state.cursor().mouseDown(newCursorPosition);
        }

        this.renderContent(true, this.getFirstModifiedPage()); // content appearence can change based on the new click position
        this.renderCursor();

        this.editor.onmousemove = (evt: MouseEvent) => {
            evt.preventDefault();
            this.removeCursor();

            let moveRange = Page.getXYCaretRange(evt.clientX, evt.clientY);
            let newSelectionEnd = this.cursorPositionFromRange(moveRange);

            if (newSelectionEnd != null) {
                this.state.cursor().mouseMove(newSelectionEnd);
            }
            this.renderContent(true, this.getFirstModifiedPage()); // content appearence can change based on the new cursor position
            this.renderCursor();
        }
    
        this.editor.onmouseup = (evt: MouseEvent) => {
            evt.preventDefault();
            this.editor.onmousemove = null;
            this.editor.onmouseup = null;
        }
    }

    public doubleClickAction() {
        this.state.cursor().doubleClick();

        this.renderContent(true, this.getFirstModifiedPage()); // content appearence can change based on the new click position
        this.renderCursor();
    };

    public tripleClickAction() {
        this.state.cursor().tripleClick();

        this.renderContent(true, this.getFirstModifiedPage()); // content appearence can change based on the new click position
        this.renderCursor();
    };

    /* Rendering Methods */

    public renderContent(fast: boolean = false, firstDirtyPage: number = 0) {
        if (
            this.tupleEquals(this.wasInLatex, this.state.cursor().activeLatexBlockPosition()) && 
            !this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])
        ) {
            return;
        }

        this.state.content().purify(this.state.cursor());
        this.state.content().coalesce(this.state.cursor());
        if (fast) {
            let renderError = this.state.content().fastRender(this.editor, this.state.cursor(), this.blockMap, firstDirtyPage);
            this.setRenderError(renderError);
        } else {
            this.state.content().render(this.editor, this.state.cursor(), this.blockMap);
        }
        this.editor.focus({ preventScroll: true });

        if (!this.state.cursor().inASelection() && (!(this.tupleEquals(this.wasInLatex, this.state.cursor().activeLatexBlockPosition()))
            && (!this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])))) {
            this.wasInLatex = this.state.cursor().activeLatexBlockPosition();
            let activeParagraphNumber = this.state.cursor().getCursorPosition().getParagraphNumber();
            let activeBlockNumber = this.state.cursor().getCursorPosition().getBlockNumber();
            let activeBlockCoordinates = this.state.cursor().getCursorPosition().getBlockCoordinates(this.blockMap);
            let activeLatexBlockElement = document.getElementById(activeBlockCoordinates.join("-"));
            let firstDirtyPage = this.getFirstModifiedPage();

            activeLatexBlockElement?.addEventListener("blur", function(this: Controller) {
                this.state.save(firstDirtyPage);

                this.wasInLatex = [-1, -1];
            }.bind(this), true);

            let mathField = this.MQ.MathField(activeLatexBlockElement!, {
                spaceBehavesLikeTab: false,
                handlers: {
                    edit: async () => {
                        // Note: this handler is also called after deleteOutOf for some weird reason :(
                        this.state.content().dynamicLatexEdit(this.state.cursor(), mathField.latex());
                        this.savedInCloud = false;
                        await this.scrollIfNeeded();
                    },
                    moveOutOf: async (direction: number, _mathField: any) => {
                        let [newCursorPosition, newSelectionEnd] = this.state.content().dynamicLatexMoveOut(this.state.cursor(), direction);

                        this.state.cursor().dynamicLatexMoveOut(newCursorPosition, newSelectionEnd, direction);
                        this.renderContent(true, firstDirtyPage);
                        await this.scrollIfNeeded();
                        this.renderCursor();
                        this.state.save(this.getFirstModifiedPage());

                        this.wasInLatex = [-1, -1];

                        // Focus back on the editor to allow users to type again
                        this.editor.focus({ preventScroll: true});
                    },
                    enter: async (_mathField: any) => {
                        let [newCursorPosition, newSelectionEnd] = this.state.content().dynamicLatexMoveOut(this.state.cursor(), this.MQ.R);
                        this.state.cursor().dynamicLatexMoveOut(newCursorPosition, newSelectionEnd, this.MQ.R);
                        this.renderContent(true, firstDirtyPage);
                        await this.scrollIfNeeded();
                        this.renderCursor();
                        this.state.save(this.getFirstModifiedPage());

                        this.wasInLatex = [-1, -1];

                        // Focus back on the editor to allow users to type again
                        this.editor.focus({ preventScroll: true});
                    },
                    deleteOutOf: async (_direction: number, _mathField: any) => {
                        let [newCursorPosition, newSelectionEnd] = this.state.content().dynamicLatexMoveOut(this.state.cursor(), this.MQ.L);
                        this.state.cursor().dynamicLatexMoveOut(newCursorPosition, newSelectionEnd, this.MQ.L);
                        this.renderContent(true, firstDirtyPage);
                        await this.scrollIfNeeded();
                        this.renderCursor();
                        this.state.save(this.getFirstModifiedPage());

                        this.wasInLatex = [-1, -1];

                        // Focus back on the editor to allow users to type again
                        this.editor.focus({ preventScroll: true});
                    }
                }
            });
            let initialLatex = this.state.content().at(activeParagraphNumber).at(activeBlockNumber).getText();
            mathField.focus();

            // Clear the current mathfield from latex, and rewrite the content to it
            mathField.latex("");
            mathField.write(initialLatex);
        }
    }

    public renderCursor() {
        if (
            this.tupleEquals(this.wasInLatex, this.state.cursor().activeLatexBlockPosition()) && 
            !this.tupleEquals(this.state.cursor().activeLatexBlockPosition(), [-1, -1])
        ) {
            return;
        }
        this.state.cursor().render(this.editor, this.blockMap);
    }

    public removeCursor() {
        // This method should be replaced by a faster method that just removes the cursor
        // instead of re-rendering the entire content every time
        let firstDirtyPage = this.getFirstModifiedPage();
        this.renderContent(true, firstDirtyPage);
    }

    /* Style Handlers */

    public async textStyleHandler(style: TextStyle | null) {
        let inSelection = this.state.cursor().inASelection();
        let firstDirtyPage = this.getFirstModifiedPage();
        if (inSelection) {
            this.state.save(firstDirtyPage);
        }
        let [newCursorPosition, newSelectionEnd] = this.state.content().textStyle(this.state.cursor(), style);
        this.state.cursor().style(newCursorPosition, newSelectionEnd);
        this.savedInCloud = false;

        this.renderContent(true, firstDirtyPage);
        if (inSelection) { // We only want to scroll when a chunk of text changes styles.
            await this.scrollIfNeeded();
        }
        this.renderCursor();
        if (inSelection) {
            this.state.save(this.getFirstModifiedPage());
        }
    }

    public async latexStyleHandler(style: LatexStyle) {
        if (this.state.cursor().inASelection()) {
            return;
        }
        let firstDirtyPage = this.getFirstModifiedPage();

        if (style == LatexStyle.DYNAMIC) {
            this.state.save(firstDirtyPage);
        }
        let [newCursorPosition, newSelectionEnd] = this.state.content().latexStyle(this.state.cursor(), style);
        this.state.cursor().style(newCursorPosition, newSelectionEnd);
        this.savedInCloud = false;

        // we do not want to scroll to newly created latex blocks (we get bounding rect errors)

        this.renderContent(true, firstDirtyPage);
        this.renderCursor();
    }

    public async insertParagraphHandler(paragraph: Paragraph) {
        let firstDirtyPage = this.getFirstModifiedPage();
        let [newCursorPosition, newSelectionEnd] = this.state.content().insertParagraph(this.state.cursor(), paragraph);
        this.state.cursor().insertParagraph(newCursorPosition, newSelectionEnd);
        this.savedInCloud = false;

        this.renderContent(true, firstDirtyPage);
        await this.scrollIfNeeded(); // renders just the content
        this.renderCursor();
    }

    /* Page Methods */

    // This method should be called BEFORE any data structure changes to avoid issues
    public getFirstModifiedPage(): number {
        let min = CursorPosition.min(this.state.cursor().getCursorPosition(), this.state.cursor().getSelectionEnd());
        let firstModifiedPage = min.getBlockCoordinates(this.blockMap)[0];

        // decrementing the min to prevent the following edge case bug:
        // we are deleting from what appears to be the beginning of page 1, say,
        // which makes the cursor go back to page 0, but we don't re-render page 0.
        // So, if the min is one more than the start of the page, decrement the first modified page.
        let duplicate = CursorPosition.duplicate(min);
        duplicate.decrement(this.state.content());
        if (this.state.content().getPages()[firstModifiedPage].getStartPosition().greaterThanOrEqualTo(duplicate) && firstModifiedPage > 0) {
            firstModifiedPage--;
        }

        return firstModifiedPage;
    }

    public triggerPagesInViewPort(editor: HTMLElement) {
        let pages = editor.querySelectorAll('.page');

        const observer = new IntersectionObserver((_entries: IntersectionObserverEntry[]) => {
            // pass in numPages because we don't want to mark any pages as dirty.
            this.renderContent(true, this.state.content().numPages());
        }, {
            root: null,
            threshold: 0
        });

        pages.forEach((page) => observer.observe(page));

        const mutationObserver = new MutationObserver(() => {
            pages = editor.querySelectorAll('.page');
            pages.forEach((page) => observer.observe(page));
        });

        mutationObserver.observe(editor, { childList: true, subtree: true });
    }

    /* Scrolling Functions */

    public async scrollIfNeeded() {
        // A note: this method can only be called when everything is rendered except the cursor.
        // So, the general flow for normal rendering is: 1) Compute, 2) Render content, 3) Scroll, 4) Render Cursor, 5) Save.
        // For fast render, it is: 1) Get first modified page, 2) Compute, 3) Render content, 4) Scroll, 5) Render cursor, 6) Save
        // We want to get the first modified page first because the blockmap is only accurate before the data structures change
        if (this.cursorNotVisible()) {
            this.scrolling = true;
            let sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
            animateScroll.scrollTo(this.toScrollPosition(), {duration: Controller.SCROLL_DURATION, smooth: 'easeInOutQuad'});
            await sleep(Controller.SCROLL_DURATION); // Sleep to allow scrolling to complete
            this.scrolling = false;
        }
    }

    // returns the position we should scroll to
    public toScrollPosition(): number {
        let cursorRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
        let cursorTop = cursorRect.top + window.scrollY;
        let cursorHeight = cursorRect.height;
        let viewPortHeight = window.innerHeight;
        let toolbarPixelsHeight = (viewPortHeight * 10) / 100;
        let positionToScrollTo = cursorTop - toolbarPixelsHeight - cursorHeight;
        return positionToScrollTo;
    }

    public cursorNotVisible(): boolean {
        let cursorRect = this.getBoundingRectFromOffset(this.state.cursor().getSelectionEnd());
        let cursorTop = cursorRect.top + window.scrollY;
        let cursorHeight = cursorRect.height;
        let viewPortHeight = window.innerHeight;
        let toolbarPixelsHeight = (viewPortHeight * 10) / 100;

        // cursorTooHigh is true if the cursor is not visible because it is to high for us to see.
        let cursorTooHigh = window.scrollY + toolbarPixelsHeight - cursorTop + cursorHeight > 0.1;

        // cursorTooLow is true if the cursor is not visible because it is too low for us to see.
        let cursorTooLow = viewPortHeight + window.scrollY < cursorTop + 2 * cursorHeight;

        return cursorTooHigh || cursorTooLow;
    }

    /* HTML Helpers */

    public getBoundingRectFromOffset(cursorPosition: CursorPosition): DOMRect {
        let coordinates = cursorPosition.getBlockCoordinates(this.blockMap);
        let paragraphElement = this.editor.children[coordinates[0]].children[coordinates[1]];
        let blockElement = paragraphElement.children[coordinates[2]];
        let startingOffset = coordinates[3];

        // Did this so that a cursorPosition in dynamic latex does not just have a (0,0,0,0) bounding
        // rect for scrolling
        if (cursorPosition.inDynamicLatex(this.state.content())) {
            return blockElement.getBoundingClientRect();
        }

        if (blockElement.firstChild == null) {
            return paragraphElement.getBoundingClientRect();
        }
        let range = document.createRange()
        range.setStart(blockElement.firstChild, cursorPosition.getOffset() - startingOffset);
        range.setEnd(blockElement.firstChild, cursorPosition.getOffset() - startingOffset);
        return range.getBoundingClientRect();
    }

    public cursorPositionFromRange(clickRange: Range | null): CursorPosition {
        if (clickRange == null || clickRange.startContainer == null) {
            return new CursorPosition(0, 0, 0);
        }

        let elementNode: any = clickRange.startContainer;
        if (elementNode.nodeType != Node.ELEMENT_NODE) {
            elementNode = elementNode.parentElement;
        }

        if (elementNode == null) {
            return new CursorPosition(0, 0, 0);
        }

        let paragraphElement = elementNode.closest(".paragraph");
        let blockElement = elementNode.closest(".block");

        if (paragraphElement == null) {
            return new CursorPosition(0, 0, 0);
        }

        let paragraphNum = parseInt(paragraphElement.getAttribute("content-structure"));
        if (isNaN(paragraphNum)) {
            return new CursorPosition(0, 0, 0);
        }

        if (blockElement == null) {
            return new CursorPosition(paragraphNum, 0, 0); // we are in a paragraph with only a single empty block
        }

        let blockNum = parseInt(blockElement.getAttribute("content-structure").split("-")[1]);
        if (isNaN(blockNum)) {
            return new CursorPosition(0, 0, 0);
        }

        let blockOffset = parseInt(blockElement.id.split("-")[3]);

        return new CursorPosition(paragraphNum, blockNum, clickRange.startOffset + blockOffset);
    }

    public tupleEquals([a, b]: [number, number], [c, d]: [number, number]): boolean {
        return a == c && b == d;
    }

    /* Getters */

    public getEditorHTMLElement(): HTMLElement {
        return this.editor;
    }

    public getState(): State {
        return this.state;
    }

    public getSavedInCloud(): boolean {
        return this.savedInCloud;
    }
}