import { CursorPosition } from "./editor/CursorPosition";
import { AWSLibrary } from "./AWSLibrary";

// interface for each document in the document list on the documents page
// (showing all of a particular user's documents)
export interface Document {
    documentMetadata: DocumentMetadata,
    content: string | undefined, // a json string with the content of the document
}

// interface for each document's metadata in the document list on the documents page
// (showing all of a particular user's documents)
export interface DocumentMetadata {
    title: string | undefined,
    lastModified: Date | undefined,
    owner: string | undefined,
}

// interface for the data associated with the current signed in user
export interface UserData {
    firstName: string,
    lastName: string,
    email: string,
}

export interface AuthData {
    isLoggedIn: boolean;
    userData: UserData;
    token: string | null;
}

export enum ColorModeSwitcherLocation {
    EDITOR,
    NAVBAR
}

export enum BlockDataType {
    TEXT = "TEXT",
    LATEX = "LATEX",
}

export interface BlockData {
    type: BlockDataType,
    text: string,
}

export interface TextBlockData extends BlockData {
    styles: {[key: string]: boolean}
}

export interface LatexBlockData extends BlockData {
    style: string
}

export enum ParagraphDataType {
    TEXT = "TEXT",
    LATEX = "LATEX",
}

export interface ParagraphData {
    type: ParagraphDataType,
    blocks: BlockData[]
}

export interface ContentData {
    paragraphs: ParagraphData[]
}

export interface CursorData {
    cursorPosition: CursorPosition;
    selectionEnd: CursorPosition;
}

export interface StateData {
    contentData: ContentData;
    cursorData: CursorData;
    firstModifiedPageData: number;
}

export const AwsLibrary = new AWSLibrary();